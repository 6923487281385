import React from "react"
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import Meta from "../components/meta"
import featuresBackgroundImg from "../images/featurs bg 1.svg";
import featuresBackground2Img from "../images/blue_layer.png";
import userCheckIcon from "../images/Icon awesome-user-check.svg";
import trophyIcon from "../images/Icon awesome-trophy.svg";
import thumbUpIcon from "../images/Icon awesome-thumbs-up.svg";
import closeIcon from "../images/Icon feather-plus-circle.svg";
import graphicShadow from "../images/graphic shadow.svg";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const CloseButton = () => (
    <a href="javascript:void(0)" className="close_po">
        <img src={closeIcon} alt="close"/>
    </a>
);

const Features = ({data, location}) => {
    return data && (
        <Layout location={location}>
            <Meta title="Features"/>
            <div className="banner platform_banner features_banner">
                <div className="wrapper">
                    <div className="banner_content">
                        <h1>An ongoing incentivization process<br/><strong> not just a bonus at the end of the
                            month</strong></h1>
                        <p>Use our comprehensive set of incentives to ensure everyone is moving forward, not just the
                            outstanding reps</p>
                    </div>
                </div>
            </div>
            <div className="features_wrap">
                <div className="wrapper">
                    <div className="features cf">
                        <div className="features_img left_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic1.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content right_fe motivation_plan">
                            <h4>Motivational Plan</h4>
                            <h6>Our AI engine checks behavioral parameters, builds a personalized motivation profile and
                                provides a totally individualized incentivization plan.</h6>
                            <p>Our solution automatically generates individual challenges, incentives, collaborations,
                                head-on friendly competitions and positive feedback.</p>
                            <Link to={'/book-a-demo'} className="btn">REQUEST A DEMO</Link>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="features cf">
                        <div className="features_img right_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic2.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content left_fe leaderboards">
                            <h4>Leaderboards</h4>
                            <h6>Track all metrics on the leaderboard.</h6>
                            <p>Get a single cohesive view with a performance dashboard featuring scores and rankings,
                                which change as your reps update activity information. Customize the background, colors
                                and layout to fit your company style and brand.</p>
                        </div>
                    </div>
                </div>
                <div className="floating_re">
                    <div className="floating_img"><img src={featuresBackgroundImg} alt="background"/></div>
                    <div className="wrapper">
                        <div className="features cf">
                            <div className="features_img left_fe">
                                <div className="ui_img">
                                    <Img fluid={data.featuresGraphic3.childImageSharp.fluid}/>
                                    <span><img src={graphicShadow} alt="shadow"/></span>
                                </div>
                            </div>
                            <div className="features_content right_fe performance">
                                <h4>Performance</h4>
                                <h6>Visualize progress against customized goals, aligned with the corporate's targets
                                    -encouraging attainment and accountability.</h6>
                                <p>• Get visibility into progress against goals. Visible goals are more likely to be
                                    reached. </p>
                                <p>• Create public accountability on individual and team KPIs. </p>
                                <p>• Align your employee, team and company targets and measure progress.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper mabager_love">
                    <h2>HERE'S WHY REPS LOVE IT</h2>
                    <div className="box_process cf">
                        <div className="box" style={{height: '446px'}}>
                            <span style={{backgroundColor: '#9824C6'}}>
                                <img src={userCheckIcon} alt="transparency"/>
                            </span>
                            <h2>Transparency</h2>
                            <p>
                                With Enerjoy, at any given moment, reps receive fully transparent data regarding goals,
                                performance and their progress, together with clarity regarding competitions, feedback
                                and upcoming prizes
                            </p>
                        </div>
                        <div className="box" style={{height: '446px'}}>
                            <span style={{backgroundColor: '#FDD85B'}}>
                                <img src={trophyIcon} alt="trophy"/>
                            </span>
                            <h2>Chance to win</h2>
                            <p>It's a completely different work experience with Enerjoy .... everyone has the
                                opportunity to progress, succeed and be rewarded along the way.</p>
                        </div>
                        <div className="box" style={{height: '446px'}}>
                            <span style={{backgroundColor: '#38EE7D'}}>
                                <img src={thumbUpIcon} alt="thumb up"/>
                            </span>
                            <h2>Fun and Joy</h2>
                            <p>Enerjoy helps to instill an organizational culture that promotes collaboration and
                                communication between employees, makes every effort and achievement meaningful, and
                                focuses on the employee, their unique experience and value</p>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="features cf">
                        <div className="features_img right_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic4.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content left_fe feedback_point">
                            <h4>Feedback and points</h4>
                            <h6>Maximize employee effectiveness and discover how each member of your team is performing
                                with our scorecards.</h6>
                            <p>Create customized scorecards with core sales KPIs, motivating team members to work
                                towards key objectives. Define and track short and long-term metrics.</p>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="features cf">
                        <div className="features_img left_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic5.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content right_fe performance contests">
                            <h4>Contests - challenges</h4>
                            <h6>Challenges wizard with maximum flexibility to suit every need and goal.</h6>
                            <p>An automatic mode that builds and activates various challenges and competitions</p>
                        </div>
                    </div>
                </div>
                <div className="floating_re">
                    <div className="floating_img right"><img src={featuresBackground2Img} alt="background"/></div>
                    <div className="wrapper">
                        <div className="features cf">
                            <div className="features_img right_fe">
                                <div className="ui_img">
                                    <Img fluid={data.featuresGraphic6.childImageSharp.fluid}/>
                                    <span><img src={graphicShadow} alt="shadow"/></span>
                                </div>
                            </div>
                            <div className="features_content left_fe joystore">
                                <h4>Joystore</h4>
                                <h6>
                                    Convert points into rewards in our custom rewards store, where accumulated points
                                    can be converted into vouchers for desirable gifts & brands.
                                </h6>
                                <p>• Manage your incentive budget transparently with a clear ROI.</p>
                                <br/>
                                <p>
                                    • Fill your rewards store with preferred prizes to motivate all your team;
                                    outstanding employees, those who are improving and even new employees.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="features cf management">
                        <div className="features_img left_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic7.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content right_fe goals_management">
                            <h4>Goals Management</h4>
                            <h6>Comprehensive management of team and employee goals.</h6>
                            <p>• Recommendations for division of team goals between employees.</p>
                            <p>• Alerts on positive and negative trends.</p>
                            <p>• Communication to employees at the click of a button.</p>
                            <Link to={'/book-a-demo'} className="btn">REQUEST A DEMO</Link>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="features cf management">
                        <div className="features_img right_fe">
                            <div className="ui_img">
                                <Img fluid={data.featuresGraphic8.childImageSharp.fluid}/>
                                <span><img src={graphicShadow} alt="shadow"/></span>
                            </div>
                        </div>
                        <div className="features_content left_fe shift_management">
                            <h4>Shifts Management</h4>
                            <h6>
                                Easy-to-use feature for managing employee shifts, controlling work hours, communicating
                                work schedules to employees - at the touch of a button.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="salse_col">
                <div className="wrapper">
                    <div className="in_sales cf">
                        <div className="text_right">
                            <h3>Want to increase your sales?</h3>
                        </div>
                        <div className="con_mail">
                            <RequestDemoMiniForm/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Features;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const query = graphql`
    query {
        featuresGraphic1: file(relativePath: { eq: "Features graphic 1.png" }) { ...fluidImage }
        featuresGraphic2: file(relativePath: { eq: "Features graphic 2.png" }) { ...fluidImage }
        featuresGraphic3: file(relativePath: { eq: "Group 164.png" }) { ...fluidImage }
        featuresGraphic4: file(relativePath: { eq: "Features graphic 4.png" }) { ...fluidImage }
        featuresGraphic5: file(relativePath: { eq: "Features graphic 5.png" }) { ...fluidImage }
        featuresGraphic6: file(relativePath: { eq: "Features graphic 6.png" }) { ...fluidImage }
        featuresGraphic7: file(relativePath: { eq: "Features graphic 7.png" }) { ...fluidImage }
        featuresGraphic8: file(relativePath: { eq: "Features graphic 8.png" }) { ...fluidImage }
    }
`;